import { useState } from "react";
import {
  Box
} from "@mui/material";
import { modelList } from "../utils/Config";
import MainAppBar from "../components/MainAppBar";
import HistoryList from "../components/HistoryList";
import InputAppBar from "../components/InputAppBar";
import SettingsDialog from "../components/SettingsDialog";
import HelpDialog from "../components/HelpDialog";
import Message from "../components/Message";


type Role = "system" | "user";
type History = {
  role: Role;
  content: string;
};
type Model = (typeof modelList[number])["value"];
type Severity = "success" | "info" | "warning" | "error";

function Main(): JSX.Element {
  // 処理中かどうか
  const [isRunning, setIsRunning] = useState<boolean>(false);

  // 送信した文章の履歴
  const [historyList, setHistoryList] = useState<History[]>([]);

  // 設定を開くかどうか
  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
  // ヘルプを開くかどうか
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false);

  // 選択された生成AIモデル
  const [selectedModel, setSelectedModel] = useState<Model>(modelList[0]["value"]);
  // 送信する履歴の数
  const [historyCount, setHistoryCount] = useState<number>(2);
  // ストリーム出力するかどうか
  const [isStream, setIsStream] = useState<boolean>(true);

  // メッセージの内容
  const [messageText, setMessageText] = useState<string>("");
  // メッセージの重大度
  const [messageSeverity, setMessageSeverity] = useState<Severity>("info");
  // メッセージを表示するかどうか
  const [messageOpen, setMessageOpen] = useState<boolean>(false);

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <MainAppBar
        isRunning={isRunning}
        setSettingsDialogOpen={setSettingsDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
        selectedModel={modelList.find((model) => model.value === selectedModel)?.label || ""}
      />

      <HistoryList
        historyList={historyList}
      />

      <InputAppBar
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        historyList={historyList}
        setHistoryList={setHistoryList}
        selectedModel={selectedModel}
        historyCount={historyCount}
        isStream={isStream}
        setSettingsDialogOpen={setSettingsDialogOpen}
        setMessageText={setMessageText}
        setMessageSeverity={setMessageSeverity}
        setMessageOpen={setMessageOpen}
      />

      <SettingsDialog
        settingsDialogOpen={settingsDialogOpen}
        setSettingsDialogOpen={setSettingsDialogOpen}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        historyCount={historyCount}
        setHistoryCount={setHistoryCount}
        isStream={isStream}
        setIsStream={setIsStream}
      />

      <HelpDialog
        helpDialogOpen={helpDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
      />

      <Message
        messageText={messageText}
        messageSeverity={messageSeverity}
        messageOpen={messageOpen}
        setMessageOpen={setMessageOpen}
      />
    </Box>
  );
}

export default Main;
